import '../../common.css';
import { Row, Button, Col, Container } from 'react-bootstrap';
import FilterComponent from '../components/FilterPhaseComponent';
import { useEffect, useState } from 'react';
import api from '../api/CallUserApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { toastConfig } from '../utils';
import { PHASE } from '../enum/PhaseEnum';
import ChangeLessonModal from '../components/modal/ChangeLessonModal';
import ReportsTable from '../components/reportTables/UserReportTable';
import { useDispatch } from 'react-redux';
import { setDisplayNoReportTooltip } from '../../store/noReportTooltipReducer';
import { setPhase } from '../../store/filterReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { useErrorBoundary } from 'react-error-boundary';
import { BulkUpdatePhase } from '../interface/BulkUpdatePhase';
import { Report } from '../interface/Report';
import { removeAllTmpDataAndEditTypeById } from '../localStorageUtils';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import ReportsCheckboxModeComponent from '../components/ReportsCheckboxModeComponent';
import ConfirmApproveModal from '../components/modal/ConfirmApproveModal';
import ConfirmBulkDeleteModal from '../components/modal/ConfirmBulkDeleteModal';
import { ACCOUNT, getBossAccount, getSubordinateAccount } from '../enum/AccountEnum';
import { AccountStrings } from '../interface/AccountStrings';
import Axios from 'axios';

const ShowReports: React.FunctionComponent = () => {
  const globalInstitutionResponsibleTerm = useSelector((state: RootState) => state.institution.responsibleTerm);
  const initialPhase = useSelector((state: RootState) => state.reportFilter.phase);
  const [phaseFilter, setPhaseFilter] = useState<string>(initialPhase ? initialPhase : PHASE.NOT_SUBMITTED);
  const [isDisableSubmitDeleteBtn, setIsDisableSubmitDeleteBtn] = useState(true); // 複数可
  const [isDisplayConfirmDeleteModal, setIsDisplayConfirmDeleteModal] = useState<boolean>(false);
  const [isDisplayConfirmApproveModal, setIsDisplayConfirmApproveModal] = useState<boolean>(false);
  const [isDisplayChangeClassInfoModal, setIsDisplayChangeClassInfoModal] = useState<boolean>(false);
  const [isDisableCopyBtn, setIsDisableCopyBtn] = useState(true); // 複数可
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isClearSelectedRow, setIsClearSelectedRow] = useState(false);
  const [hideStatusColumn, setHideStatusColumn] = useState<boolean>(true);
  const navigate = useNavigate();
  const [isDisplayEdit, setIsDisplayEdit] = useState<boolean>(initialPhase ? initialPhase === PHASE.NOT_SUBMITTED : true);
  const [selectableRowsVisibleOnly, setSelectableRowsVisibleOnly] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const moveConfirmPage = (row: any) => window.open('/users/report/' + row._id, '_blank');
  const moveEditPage = (row: any) => navigate('/users/report/edit/' + row._id + '/lesson');
  const moveCopyLessonPage = (row: any) => navigate('/users/report/copy/' + row._id + '/bigMediaCategory');
  const moveCopyLessonAndMediaPage = (row: any) => navigate('/users/report/copy/' + row._id + '/work');

  const accountStrings: AccountStrings = {
    action: '提出',
    userAccount: ACCOUNT.USERS,
    bossAccount: getBossAccount(ACCOUNT.USERS),
    subordinateAccount: ACCOUNT.USERS,
    imageFileName: 'users',
  };

  const updateDisplayReports = async (phase: string) => {
    try {
      dispatch(startLoading());
      const originReports = await api.getReports(phase);
      setReports(originReports);
      dispatch(setDisplayNoReportTooltip(originReports.length === 0 && phase === PHASE.NOT_SUBMITTED));
      setButtonDisable(0);
      setIsClearSelectedRow(!isClearSelectedRow);
      setSelectedRows([]);
    } finally {
      dispatch(stopLoading());
    }
  };

  const setButtonDisable = (num: number) => {
    if (phaseFilter === PHASE.NOT_SUBMITTED) {
      if (num === 0) {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(true);
      } else if (num === 1) {
        setIsDisableSubmitDeleteBtn(false);
        setIsDisableCopyBtn(false);
      } else {
        setIsDisableSubmitDeleteBtn(false);
        setIsDisableCopyBtn(false);
      }
    } else {
      if (num === 0) {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(true);
      } else if (num === 1) {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(false);
      } else {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(false);
      }
    }
  };

  const handleSelectRow = ({ selectedRows }: { selectedRows: any }) => {
    setButtonDisable(selectedRows.length);
    setSelectedRows(selectedRows);
  };

  const submitItems = async () => {
    try {
      dispatch(startLoading());
      const targetItems: BulkUpdatePhase[] = selectedRows.map((item) => ({
        _id: item._id,
        phase: PHASE.USER_CONFIRMED,
      }));
      await api.bulkUpdate(targetItems);
      toast.success('提出が完了しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400 || e.response.status === 404) {
        toast.error('提出に失敗しました。画面を更新してください。', toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter);
  };

  const deleteItems = async () => {
    try {
      dispatch(startLoading());
      const deleteIds: string[] = selectedRows.map((item) => item._id);
      await api.bulkDelete(deleteIds);
      setIsDisplayConfirmDeleteModal(false);
      toast.success('削除が完了しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400 || e.response.status === 404) {
        toast.error('削除に失敗しました。画面を更新してください。', toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter);
  };

  const copyReports = async (formData: any) => {
    try {
      dispatch(startLoading());
      const targetIds: string[] = selectedRows.map((item) => item._id);
      const data = await Promise.all(
        targetIds.map(async (id) => {
          const originReport = await api.getReport(id);
          return {
            phase: PHASE.NOT_SUBMITTED,
            classInfo: formData,
            media: originReport.media,
            work: originReport.work,
          };
        })
      );
      if (data.length !== 0) {
        await api.bulkCreate(data);
        toast.success('コピー作成が完了しました。', toastConfig());
        setIsDisplayChangeClassInfoModal(false);
      }
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400) {
        toast.error('コピー作成に失敗しました。', toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter);
  };

  useEffect(() => {
    (async () => {
      await updateDisplayReports(phaseFilter);
    })();
    // 利用報告詳細確認ページから戻ってきたら一覧を更新するイベントを追加
    const focusFunction = async () => await updateDisplayReports(phaseFilter);
    window.addEventListener('focus', focusFunction, false);
    return () => window.removeEventListener('focus', focusFunction, false);
  }, [phaseFilter]);

  useEffect(() => {
    (async () => {
      const profile: BaseUserInfo = await api.getProfile();
      removeAllTmpDataAndEditTypeById(profile._id);
      await updateDisplayReports(phaseFilter);
    })();
    return () => {
      dispatch(setDisplayNoReportTooltip(false));
    };
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
              利用報告一覧 ＜{globalInstitutionResponsibleTerm.year}年{globalInstitutionResponsibleTerm.month}月＞
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 12 }} lg={{ span: 5, offset: 7 }} style={{ marginTop: '20px' }}>
            <FilterComponent
              onFilter={(event: any) => {
                const phase = event.target.value;
                dispatch(setPhase(phase));
                setIsDisplayEdit(phase === PHASE.NOT_SUBMITTED);
                setHideStatusColumn(phase === PHASE.NOT_SUBMITTED);
                setPhaseFilter(phase);
              }}
              filterText={phaseFilter}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col md={{ span: 4 }}>
            <ReportsCheckboxModeComponent
              selectableRowsVisibleOnly={selectableRowsVisibleOnly}
              handleChangeFunction={() => {
                setIsClearSelectedRow((prev) => !prev);
                setSelectableRowsVisibleOnly((prev) => !prev);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReportsTable
              reports={reports}
              selectRow={handleSelectRow}
              moveConfirmPage={moveConfirmPage}
              moveEditPage={moveEditPage}
              moveCopyLessonPage={moveCopyLessonPage}
              moveCopyLessonAndMediaPage={moveCopyLessonAndMediaPage}
              isClearSelectedRow={isClearSelectedRow}
              isDisplayEdit={isDisplayEdit}
              isDisplayCopy={true}
              hideStatusColumn={hideStatusColumn}
              selectableRowsVisibleOnly={selectableRowsVisibleOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ta-right">
              <Button
                variant="danger"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmDeleteModal(true)}
              >
                削除
              </Button>
              <Button
                variant="primary"
                className="m-10"
                type="button"
                disabled={isDisableCopyBtn}
                onClick={() => setIsDisplayChangeClassInfoModal(true)}
              >
                授業情報を編集してコピー
              </Button>
              <Button
                variant="primary"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmApproveModal(true)}
              >
                提出
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmBulkDeleteModal show={isDisplayConfirmDeleteModal} setShow={setIsDisplayConfirmDeleteModal} nextStep={deleteItems} />
      <ChangeLessonModal
        show={isDisplayChangeClassInfoModal}
        setShow={setIsDisplayChangeClassInfoModal}
        changeFormData={copyReports}
        changeInstitution={() => {}}
        selectInstitutions={false}
        institutions={[]}
      />
      <ConfirmApproveModal
        isDisplayModal={isDisplayConfirmApproveModal}
        accountStrings={accountStrings}
        handleCloseModal={() => setIsDisplayConfirmApproveModal(false)}
        handleClickOk={submitItems}
      />
    </>
  );
};

export default ShowReports;
