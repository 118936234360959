import { Button, Modal } from 'react-bootstrap';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  nextStep: () => void;
}

const ConfirmBulkDeleteModal: React.FC<ModalProps> = ({ show, setShow, nextStep }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>削除確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>チェックした利用報告を削除します。よろしいですか？</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => setShow(false)}>
          キャンセル
        </Button>
        <Button variant="danger" onClick={() => nextStep()}>
          削除する
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmBulkDeleteModal;
