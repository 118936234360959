import '../../common.css';
import { Row, Button, Col, Container } from 'react-bootstrap';
import FilterPhase from '../components/FilterPhaseComponent';
import FilterOwner from '../components/FilterOwnerComponent';
import FilterInstitution from '../components/FilterDataTableComponent';
import { useEffect, useState, useRef } from 'react';
import api from '../api/CallAdminApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { toastConfig } from '../utils';
import { PHASE } from '../enum/PhaseEnum';
import ReportsTable from '../components/reportTables/AdminReportTable';
import { FileEarmarkArrowUpFill, FileEarmarkArrowDownFill } from 'react-bootstrap-icons';
import { setPhase, setInstitution, setOwner } from '../../store/filterReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { Download } from 'react-bootstrap-icons';
import { useErrorBoundary } from 'react-error-boundary';
import { BulkUpdatePhase } from '../interface/BulkUpdatePhase';
import { Comment } from '../interface/Comment';
import { removeAllTmpDataAndEditTypeById } from '../localStorageUtils';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Report } from '../interface/Report';
import { Institution } from '../interface/Institution';
import ReportsCheckboxModeComponent from '../components/ReportsCheckboxModeComponent';
import ConfirmBulkDeleteModal from '../components/modal/ConfirmBulkDeleteModal';
import ConfirmApproveModal from '../components/modal/ConfirmApproveModal';
import ConfirmSendBackModal from '../components/modal/ConfirmSendBackModal';
import { ACCOUNT, getBossAccount, getSubordinateAccount } from '../enum/AccountEnum';
import { AccountStrings } from '../interface/AccountStrings';
import Axios from 'axios';

const ShowReports: React.FunctionComponent = () => {
  const initialPhase = useSelector((state: RootState) => state.reportFilter.phase);
  const initialInstitution = useSelector((state: RootState) => state.reportFilter.institution);
  const initialOwner = useSelector((state: RootState) => state.reportFilter.owner);
  const [phaseFilter, setPhaseFilter] = useState<string>(initialPhase ? initialPhase : PHASE.OWNER_CONFIRMED);
  const [institutionFilter, setInstitutionFilter] = useState<string>(initialInstitution);
  const [ownerFilter, setOwnerFilter] = useState<string>(initialOwner);
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [isDisableSubmitDeleteBtn, setIsDisableSubmitDeleteBtn] = useState(true); // 複数可
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isClearSelectedRow, setIsClearSelectedRow] = useState(false);
  const [isDisplayConfirmDeleteModal, setIsDisplayConfirmDeleteModal] = useState<boolean>(false);
  const [isDisplayConfirmApproveModal, setIsDisplayConfirmApproveModal] = useState<boolean>(false);
  const [isDisplayConfirmSendBackModal, setIsDisplayConfirmSendBackModal] = useState<boolean>(false);
  const [sendBackReason, setSendBackReason] = useState('');
  const navigate = useNavigate();
  const [isDisplayEditButton, setIsDisplayEditButton] = useState<boolean>(initialPhase ? initialPhase === PHASE.OWNER_CONFIRMED : true);
  const [selectableRowsVisibleOnly, setSelectableRowsVisibleOnly] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const accountStrings: AccountStrings = {
    action: '承認',
    userAccount: ACCOUNT.ADMIN,
    bossAccount: ACCOUNT.ADMIN,
    subordinateAccount: getSubordinateAccount(ACCOUNT.ADMIN),
    imageFileName: 'admin',
  };

  const moveConfirmPage = (row: any) => window.open('/admin/report/' + row._id, '_blank');
  const moveEditPage = (row: any) => navigate('/admin/report/edit/' + row._id + '/lesson');

  const updateDisplayReports = async (phase: string, institutionId: string, ownerId: string) => {
    try {
      dispatch(startLoading());
      const originReports = await api.getReports(phase, institutionId, ownerId);
      setReports(originReports);
      setButtonDisable(0);
      setIsClearSelectedRow((prev) => !prev);
      setSelectedRows([]);
    } finally {
      dispatch(stopLoading());
    }
  };

  const setButtonDisable = (num: number) => {
    if (phaseFilter === PHASE.OWNER_CONFIRMED) {
      if (num === 0) {
        setIsDisableSubmitDeleteBtn(true);
      } else if (num === 1) {
        setIsDisableSubmitDeleteBtn(false);
      } else {
        setIsDisableSubmitDeleteBtn(false);
      }
    } else {
      if (num === 0) {
        setIsDisableSubmitDeleteBtn(true);
      } else if (num === 1) {
        setIsDisableSubmitDeleteBtn(true);
      } else {
        setIsDisableSubmitDeleteBtn(true);
      }
    }
  };

  const handleSelectRow = ({ selectedRows }: { selectedRows: any }) => {
    setButtonDisable(selectedRows.length);
    setSelectedRows(selectedRows);
  };

  const submitItems = async (type: string) => {
    const phase = type === '承認' ? PHASE.ADMIN_CONFIRMED : PHASE.MANAGER_CONFIRMED;
    try {
      dispatch(startLoading());
      let targetItems: BulkUpdatePhase[] = [];
      if (type === '承認') {
        // 承認
        targetItems = selectedRows.map((item) => ({ _id: item._id, phase: phase }));
      } else {
        // 差戻
        const comment: Comment | null = sendBackReason ? { content: sendBackReason } : null;
        targetItems = selectedRows.map((item) => (comment ? { _id: item._id, phase: phase, comment: comment } : { _id: item._id, phase: phase }));
      }
      await api.bulkUpdate(targetItems);
      toast.success(`${type}しました。`, toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400 || e.response.status === 404) {
        toast.error(`${type}に失敗しました。画面を更新してください。`, toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter, institutionFilter, ownerFilter);
  };

  const deleteItems = async () => {
    try {
      dispatch(startLoading());
      const deleteIds: string[] = selectedRows.map((item) => item._id);
      await api.bulkDelete(deleteIds);
      setIsDisplayConfirmDeleteModal(false);
      toast.success('削除が完了しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400 || e.response.status === 404) {
        toast.error('削除に失敗しました。画面を更新してください。', toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter, institutionFilter, ownerFilter);
  };

  const hiddenDownloadButtonRef = useRef<HTMLButtonElement>(null);
  const clickDownload = () => {
    if (hiddenDownloadButtonRef.current) {
      hiddenDownloadButtonRef.current.click();
    }
  };

  useEffect(() => {
    (async () => {
      await updateDisplayReports(phaseFilter, institutionFilter, ownerFilter);
    })();
    // 利用報告詳細確認ページから戻ってきたら一覧を更新するイベントを追加
    const focusFunction = async () => await updateDisplayReports(phaseFilter, institutionFilter, ownerFilter);
    window.addEventListener('focus', focusFunction, false);
    return () => window.removeEventListener('focus', focusFunction, false);
  }, [phaseFilter, institutionFilter, ownerFilter]);

  useEffect(() => {
    (async () => {
      const profile: BaseUserInfo = await api.getProfile();
      const originReports = await api.getReports(phaseFilter, institutionFilter, ownerFilter);
      setReports(originReports);
      const institutions = await api.getInstitutions('');
      setInstitutions(institutions);
      removeAllTmpDataAndEditTypeById(profile._id);
    })();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>利用報告一覧</div>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col md={{ span: 12 }} lg={{ span: 3 }} className="pl-0">
            <FilterPhase
              onFilter={(event: any) => {
                const phase = event.target.value;
                dispatch(setPhase(phase));
                setIsDisplayEditButton(phase === PHASE.OWNER_CONFIRMED);
                setPhaseFilter(phase);
              }}
              filterText={phaseFilter}
            />
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 4 }} className="pl-0 admin-filter">
            <b className="mt-2 filter-arrow">＞</b>
            <FilterOwner
              onFilter={async (event: any) => {
                const ownerId = event.target.value;
                dispatch(setOwner(ownerId));
                setOwnerFilter(ownerId);
                const institutions = await api.getInstitutions(ownerId);
                setInstitutions(institutions);
                setInstitutionFilter('');
              }}
              filterText={ownerFilter}
            />
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 4 }} className="pl-0 admin-filter">
            <b className="mt-2 filter-arrow">＞</b>
            <FilterInstitution
              onFilter={(event: any) => {
                const institutionId = event.target.value;
                dispatch(setInstitution(institutionId));
                setInstitutionFilter(institutionId);
              }}
              filterText={institutionFilter}
              options={institutions}
              label="教育機関"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col md={{ span: 4 }}>
            <ReportsCheckboxModeComponent
              selectableRowsVisibleOnly={selectableRowsVisibleOnly}
              handleChangeFunction={() => {
                setIsClearSelectedRow((prev) => !prev);
                setSelectableRowsVisibleOnly((prev) => !prev);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReportsTable
              reports={reports}
              selectRow={handleSelectRow}
              moveConfirmPage={moveConfirmPage}
              moveEditPage={moveEditPage}
              isClearSelectedRow={isClearSelectedRow}
              isDisplayEdit={isDisplayEditButton}
              hiddenDLButtonRef={hiddenDownloadButtonRef}
              selectableRowsVisibleOnly={selectableRowsVisibleOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 12 }} lg={{ span: 6 }}>
            <div className="d-flex">
              <div className="mr-1">
                <Button variant="primary" className="w-200" onClick={clickDownload}>
                  <Download size={20} />
                  Excelファイルで出力
                </Button>
              </div>
            </div>
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 6 }}>
            <div className="ta-right">
              <Button
                variant="danger"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmDeleteModal(true)}
              >
                削除
              </Button>
              <Button
                variant="outline-primary"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmApproveModal(true)}
              >
                <FileEarmarkArrowUpFill size={20} />
                承認
              </Button>
              <Button
                variant="outline-secondary"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmSendBackModal(true)}
              >
                <FileEarmarkArrowDownFill size={20} />
                差戻
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmBulkDeleteModal show={isDisplayConfirmDeleteModal} setShow={setIsDisplayConfirmDeleteModal} nextStep={deleteItems} />
      <ConfirmApproveModal
        isDisplayModal={isDisplayConfirmApproveModal}
        accountStrings={accountStrings}
        handleCloseModal={() => setIsDisplayConfirmApproveModal(false)}
        handleClickOk={() => submitItems('承認')}
      />
      <ConfirmSendBackModal
        isDisplayModal={isDisplayConfirmSendBackModal}
        accountStrings={accountStrings}
        handleCloseModal={() => setIsDisplayConfirmSendBackModal(false)}
        handleClickOk={() => submitItems('差戻')}
        setReason={setSendBackReason}
      />
    </>
  );
};

export default ShowReports;
