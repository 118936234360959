import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Gear, DoorClosedFill } from 'react-bootstrap-icons';
import { Col, Row, Container, Button } from 'react-bootstrap';
import api from '../../api/CallAdminApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import UnsavedReportAlertModal from './UnsavedReportAlertModal';

const Header = () => {
  const displayName = useSelector((state: RootState) => state.displayName.displayName);
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(false);
  const [isRegistOrEditOrCopyPage, setIsRegistOrEditOrCopyPage] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<() => void>(() => () => setIsDisplayModal(false));
  const navigate = useNavigate();
  const location = useLocation();

  const cautionModalWrapper = (invokedFunction: () => void) => {
    if (isRegistOrEditOrCopyPage) {
      setNextStep(() => () => {
        invokedFunction();
        setIsDisplayModal(false);
      });
      setIsDisplayModal(true);
      return;
    }
    invokedFunction();
  };

  const handleClickReportsPageButton = () => navigate('reports');
  const handleClickInstitutionsPageButton = () => navigate('institutions');
  const handleClickInfoPageButton = () => navigate('info');
  const handleClickLogoutButton = async () => {
    await api.Logout();
    navigate('login');
  };

  useEffect(() => {
    setIsRegistOrEditOrCopyPage(
      location.pathname.includes('/copy/') || location.pathname.includes('/edit/') || location.pathname.includes('/regist/')
    );
  }, [location]);

  return (
    <>
      <Container className="base-header mt-4 mb-3">
        <Row>
          <Col md="12" lg="2" xl="3">
            <img
              src={`${process.env.PUBLIC_URL}/images/new_sartras_logo.png`}
              alt="sartras_logo"
              className="header-logo"
              onClick={() => cautionModalWrapper(handleClickReportsPageButton)}
              style={{ cursor: 'pointer' }}
            />
          </Col>
          <Col md="12" lg="10" xl="9" className="d-flex flex-column">
            <div>
              <Container>
                <Row>
                  <Col md="9" lg="12" className="displayName-position">
                    {displayName}様
                  </Col>
                  <Col md="12" lg="2" className="p-0 my-2 medium-settings">
                    <div className="mr-3">
                      <Gear
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickInfoPageButton)}
                        className="d-block cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>設定</div>
                    </div>
                    <div>
                      <DoorClosedFill
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickLogoutButton)}
                        className="d-block m-auto cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>ログアウト</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div>
              <Container>
                <Row>
                  <Col md="12" lg="10">
                    <Button
                      variant="primary"
                      className="m-1"
                      as="input"
                      type="button"
                      value="利用報告一覧"
                      onClick={() => cautionModalWrapper(handleClickReportsPageButton)}
                    />
                    <Button
                      variant="primary"
                      className="m-1"
                      as="input"
                      type="button"
                      value="教育機関管理"
                      onClick={() => cautionModalWrapper(handleClickInstitutionsPageButton)}
                    />
                  </Col>
                  <Col md="12" lg="2" className="p-0 my-2 lager-settings">
                    <div className="mr-3">
                      <Gear
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickInfoPageButton)}
                        className="d-block m-auto cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>設定</div>
                    </div>
                    <div>
                      <DoorClosedFill
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickLogoutButton)}
                        className="d-block m-auto cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>ログアウト</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <UnsavedReportAlertModal isDisplayModal={isDisplayModal} handleCloseModal={() => setIsDisplayModal(false)} handleClickOk={nextStep} />
    </>
  );
};

export default Header;
