import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../api/CallManagerApi';
import { Container, Col, Button, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { toastConfig } from '../utils';
import { PHASE } from '../enum/PhaseEnum';
import ShowReportComponent from '../components/ShowReport';
import ConfirmDeleteModal from '../components/modal/ConfirmDeleteModal';
import { FileEarmarkArrowUpFill, FileEarmarkArrowDownFill } from 'react-bootstrap-icons';
import { useErrorBoundary } from 'react-error-boundary';
import { Comment } from '../interface/Comment';
import { Report } from '../interface/Report';
import ClosePageTabButton from '../components/ClosePageTabButton';
import ConfirmApproveModal from '../components/modal/ConfirmApproveModal';
import ConfirmSendBackModal from '../components/modal/ConfirmSendBackModal';
import { ACCOUNT, getBossAccount, getSubordinateAccount } from '../enum/AccountEnum';
import { AccountStrings } from '../interface/AccountStrings';
import Axios from 'axios';

const ShowReport: React.FunctionComponent = () => {
  const params = useParams();
  const [reportId, serReportId] = useState<string>('');
  const [report, setReport] = useState<Report>({} as Report);
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  const [isDisplayConfirmDeleteModal, setIsDisplayConfirmDeleteModal] = useState<boolean>(false);
  const [isDisplayConfirmApproveModal, setIsDisplayConfirmApproveModal] = useState<boolean>(false);
  const [isDisplayConfirmSendBackModal, setIsDisplayConfirmSendBackModal] = useState<boolean>(false);
  const [sendBackReason, setSendBackReason] = useState('');
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const accountStrings: AccountStrings = {
    action: '承認',
    userAccount: ACCOUNT.MANAGER,
    bossAccount: getBossAccount(ACCOUNT.MANAGER),
    subordinateAccount: getSubordinateAccount(ACCOUNT.MANAGER),
    imageFileName: 'manager',
  };

  const editItem = () => navigate('/manager/report/edit/' + reportId + '/lesson');

  const deleteItem = async () => {
    try {
      await api.deleteReport(report._id);
      setIsDisableButton(true);
      setIsDisplayConfirmDeleteModal(false);
      toast.success('利用報告を削除しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      switch (e.response.status) {
        case 400:
          toast.error('削除に失敗しました。画面を更新してください。', toastConfig());
          break;
        case 404:
          toast.error('利用報告が存在しません。', toastConfig());
          break;
        default:
          showBoundary(e.response);
      }
    }
  };

  const submitItem = async (type: string) => {
    const phase = type === '承認' ? PHASE.MANAGER_CONFIRMED : PHASE.NOT_SUBMITTED;
    const comment: Comment | null = sendBackReason ? { content: sendBackReason } : null;
    try {
      const reportData = await api.updatePhase(report._id, phase, comment);
      setReport(reportData);
      setIsDisableButton(true);
      toast.success('利用報告を' + type + 'しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      switch (e.response.status) {
        case 400:
          toast.error('利用報告を' + type + 'できませんでした。画面を更新してください。', toastConfig());
          break;
        case 404:
          toast.error('利用報告が存在しません。', toastConfig());
          break;
        default:
          showBoundary(e.response);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const id = params ? params.reportId : '';
      if (id) {
        serReportId(id);
        const reportData: Report = await api.getReport(id);
        setReport(reportData);
        setIsDisableButton(reportData.phase !== PHASE.USER_CONFIRMED);
      }
    })();

    // 利用報告詳細ページはヘッダーを非表示、離脱したら戻す
    (document.getElementsByClassName('base-header') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none';
    return () => {
      (document.getElementsByClassName('base-header') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'block';
    };
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="ta-left">
              <ClosePageTabButton />
            </div>
          </Col>
        </Row>
        <Row>
          <ShowReportComponent report={report} isDisplayCreatorName={true} />
        </Row>
        <Row>
          <Col md={6}>
            <div className="ta-left">
              <ClosePageTabButton />
            </div>
          </Col>
          <Col md={6}>
            <div className="ta-right">
              <Button variant="primary" onClick={editItem} className="m-10" disabled={isDisableButton}>
                編集
              </Button>
              <Button variant="outline-primary" onClick={() => setIsDisplayConfirmApproveModal(true)} className="m-10" disabled={isDisableButton}>
                <FileEarmarkArrowUpFill size={20} />
                承認
              </Button>
              <Button variant="outline-secondary" onClick={() => setIsDisplayConfirmSendBackModal(true)} className="m-10" disabled={isDisableButton}>
                <FileEarmarkArrowDownFill size={20} />
                差戻
              </Button>
              <Button variant="danger" onClick={() => setIsDisplayConfirmDeleteModal(true)} className="m-10" disabled={isDisableButton}>
                削除
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmDeleteModal show={isDisplayConfirmDeleteModal} setShow={setIsDisplayConfirmDeleteModal} nextStep={deleteItem} />
      <ConfirmApproveModal
        isDisplayModal={isDisplayConfirmApproveModal}
        accountStrings={accountStrings}
        handleCloseModal={() => setIsDisplayConfirmApproveModal(false)}
        handleClickOk={async () => await submitItem('承認')}
      />
      <ConfirmSendBackModal
        isDisplayModal={isDisplayConfirmSendBackModal}
        accountStrings={accountStrings}
        handleCloseModal={() => setIsDisplayConfirmSendBackModal(false)}
        handleClickOk={async () => await submitItem('差戻')}
        setReason={setSendBackReason}
      />
    </>
  );
};

export default ShowReport;
