import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Row, Col, Container, Button, Card, Form } from 'react-bootstrap';
import { useErrorBoundary } from 'react-error-boundary';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import api from '../api/CallOwnerApi';
import ArrowButtons from '../components/ArrowButtons';
import BackButton from '../components/BackButton';
import NavigationAlertModal from '../components/modal/NavigationAlertModal';
import { validateInputs } from '../validateInputs';
import Axios from 'axios';

const ShowManagerPage: React.FunctionComponent = () => {
  const [institutions, setInstitutions] = useState<any[]>([]);
  const [currentTableIndex, setCurrentTableIndex] = useState<number>(0);
  const [institutionId, setInstitutionId] = useState<string>('');
  const [institutionName, setInstitutionName] = useState<string>('');
  const [responsibleTerm, setResponsibleTerm] = useState<any>();
  const [currentName, setCurrentName] = useState<string>('');
  const [currentPhone, setCurrentPhone] = useState<string>('');
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [enabledBackPageButton, setEnabledBackPageButton] = useState<boolean>(true);
  const [enabledNextPageButton, setEnabledNextPageButton] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [changePage, setChangePage] = useState<boolean>(true);
  const [nextStepNumber, setNextStepNumber] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const location = useLocation();

  const changeManagerInfo = () => {
    const checkParams = { managerName: name, phone, email };
    const [isError, errorMessages] = validateInputs(checkParams);
    if (isError) {
      setErrorMessages(errorMessages);
      return;
    }
    setErrorMessages({});
    (async () => {
      try {
        await api.putManager(institutionId, name, phone, email);
        setCurrentName(name);
        setCurrentPhone(phone);
        setCurrentEmail(email);
        toast.success('教育機関担当者情報の変更が完了しました。', toastConfig());
      } catch (e: any) {
        if (!Axios.isAxiosError(e)) showBoundary(e);
        if (e.response.status === 400 || e.response.status === 404) {
          toast.error('教育機関担当者情報の変更に失敗しました。', toastConfig());
        } else {
          showBoundary(e.response);
        }
      }
    })();
  };

  const showBackPage = () => {
    const step = -1;
    if (checkEdited()) {
      showConfirmModal(step);
    } else {
      nextStep(step);
    }
  };

  const showNextPage = () => {
    const step = 1;
    if (checkEdited()) {
      showConfirmModal(step);
    } else {
      nextStep(step);
    }
  };

  const checkEdited = () => {
    const isNameEdited = name !== currentName;
    const isPhoneEdited = phone !== currentPhone;
    const isEmailEdited = email !== currentEmail;
    return isNameEdited || isPhoneEdited || isEmailEdited;
  };

  const nextStep = (step: number) => {
    navigate('', {
      state: { selectId: currentTableIndex + step, institutions: institutions },
      replace: true,
    });
    setChangePage(true);
  };

  const showConfirmModal = (step: number) => {
    setNextStepNumber(step);
    setShowModal(true);
  };

  useEffect(() => {
    if (!changePage) return;
    (async () => {
      const state = location.state;
      setCurrentTableIndex(state.selectId);
      setInstitutions(state.institutions);
      setEnabledBackPageButton(state.selectId !== 0);
      setEnabledNextPageButton(state.selectId !== state.institutions.length - 1);
      const institution = state.institutions.find((element) => element.tableIndex === state.selectId);
      setInstitutionId(institution.institutionId);
      setInstitutionName(institution.name);
      setResponsibleTerm(institution.responsibleTerm);
      const manager = await api.getManager(institution.institutionId);
      const name = manager.name ?? '';
      const phone = manager.phone ?? '';
      const email = manager.email ?? '';
      setCurrentName(name);
      setCurrentPhone(phone);
      setCurrentEmail(email);
      setName(name);
      setPhone(phone);
      setEmail(email);
      setChangePage(false);
      setErrorMessages({});
    })();
  }, [changePage]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
          <Col>
            <div className="ta-right">
              <ArrowButtons
                onClickBack={showBackPage}
                onClickNext={showNextPage}
                enabledBack={enabledBackPageButton}
                enabledNext={enabledNextPageButton}
              />
            </div>
          </Col>
        </Row>
        <Card className="m-10">
          <Card.Body>
            <Row>
              <Col>
                教育機関名
                <Form.Group>
                  <Form.Control type="text" value={institutionName} disabled={true} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                報告対象期間
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={(responsibleTerm?.year ?? ' - ') + '年' + (responsibleTerm?.month ?? ' - ') + '月'}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr className="border-1-solid" />
            <Row>
              <Col>
                <b>教育機関担当者</b>
              </Col>
              <Col sm={9}>
                担当者名 <span className="badge badge-danger">必須</span>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={name}
                    isInvalid={errorMessages?.managerName}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{errorMessages.managerName}</Form.Control.Feedback>
                </Form.Group>
                連絡先電話番号 <span className="badge badge-danger">必須</span>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={phone}
                    isInvalid={errorMessages?.phone}
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{errorMessages.phone}</Form.Control.Feedback>
                </Form.Group>
                連絡先メールアドレス <span className="badge badge-danger">必須</span>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={email}
                    isInvalid={errorMessages?.email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{errorMessages.email}</Form.Control.Feedback>
                </Form.Group>
                <div className="w-100 text-muted lh-1">SARTRASまたは委託先の輿論科学協会から、連絡を差し上げる場合がございます。</div>
                <div className="ta-right">
                  <Button
                    variant="primary"
                    className="m-10"
                    onClick={() => {
                      changeManagerInfo();
                    }}
                  >
                    保存
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <NavigationAlertModal
        message="編集した内容が保存されていません。ページを移動すると編集した内容が破棄されますがよろしいですか？"
        isDisplayModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        handleClickOk={() => {
          nextStep(nextStepNumber);
          setShowModal(false);
        }}
      />
    </>
  );
};
export default ShowManagerPage;
