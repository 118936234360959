import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface Props {
  message: React.ReactElement | string;
  isDisplayModal: boolean;
  handleCloseModal: () => void;
  handleClickOk: () => void;
}

const NavigationAlertModal: React.FC<Props> = ({ message, isDisplayModal, handleCloseModal, handleClickOk }) => {
  return (
    <Modal show={isDisplayModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>注意</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between' }}>
        <Button variant="outline-primary" onClick={handleCloseModal}>
          キャンセル
        </Button>
        <Button variant="danger" onClick={handleClickOk}>
          破棄する
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NavigationAlertModal;
