import '../../common.css';
import { Row, Button, Col, Container } from 'react-bootstrap';
import FilterPhase from '../components/FilterPhaseComponent';
import { useEffect, useState, useRef } from 'react';
import api from '../api/CallManagerApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { toastConfig } from '../utils';
import { PHASE } from '../enum/PhaseEnum';
import FilterUser from '../components/FilterDataTableComponent';
import ChangeLessonModal from '../components/modal/ChangeLessonModal';
import ReportsTable from '../components/reportTables/ManagerReportTable';
import { FileEarmarkArrowUpFill, FileEarmarkArrowDownFill } from 'react-bootstrap-icons';
import { setPhase, setUser, setIds } from '../../store/filterReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import SearchSerialCodeModal from '../components/modal/SearchSerialCodeModal';
import SerialCodeSearchResult from '../components/SerialCodeSearchResult';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { Download } from 'react-bootstrap-icons';
import { useErrorBoundary } from 'react-error-boundary';
import { User } from '../interface/User';
import { BulkUpdatePhase } from '../interface/BulkUpdatePhase';
import { Comment } from '../interface/Comment';
import { Report } from '../interface/Report';
import { removeAllTmpDataAndEditTypeById } from '../localStorageUtils';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import ReportsCheckboxModeComponent from '../components/ReportsCheckboxModeComponent';
import ConfirmApproveModal from '../components/modal/ConfirmApproveModal';
import ConfirmSendBackModal from '../components/modal/ConfirmSendBackModal';
import ConfirmBulkDeleteModal from '../components/modal/ConfirmBulkDeleteModal';
import { ACCOUNT, getBossAccount, getSubordinateAccount } from '../enum/AccountEnum';
import { AccountStrings } from '../interface/AccountStrings';
import Axios from 'axios';

const ShowReports: React.FunctionComponent = () => {
  const globalInstitutionResponsibleTerm = useSelector((state: RootState) => state.institution.responsibleTerm);
  const initialPhase = useSelector((state: RootState) => state.reportFilter.phase);
  const initialUser = useSelector((state: RootState) => state.reportFilter.user);
  const initialIds = useSelector((state: RootState) => state.reportFilter.ids);
  const [phaseFilter, setPhaseFilter] = useState<string>(initialPhase ? initialPhase : PHASE.USER_CONFIRMED);
  const [userFilter, setUserFilter] = useState(initialUser);
  const [isDisableSubmitDeleteBtn, setIsDisableSubmitDeleteBtn] = useState(true); // 複数可
  const [isDisplayConfirmDeleteModal, setIsDisplayConfirmDeleteModal] = useState<boolean>(false);
  const [isDisplayConfirmApproveModal, setIsDisplayConfirmApproveModal] = useState<boolean>(false);
  const [isDisplayConfirmSendBackModal, setIsDisplayConfirmSendBackModal] = useState<boolean>(false);
  const [isDisplayChangeClassInfoModal, setIsDisplayChangeClassInfoModal] = useState<boolean>(false);
  const [isDisableCopyBtn, setIsDisableCopyBtn] = useState(true); // 複数可
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isClearSelectedRow, setIsClearSelectedRow] = useState<boolean>(false);
  const [isDisplayEdit, setIsDisplayEditFlag] = useState<boolean>(initialPhase ? initialPhase === PHASE.USER_CONFIRMED : true);
  const [users, setUsers] = useState<User[]>([]);
  const [sendBackReason, setSendBackReason] = useState('');
  const [idsFilter, setIdsFilter] = useState<string[]>(initialIds);
  const [notFoundIds, setNotFoundIds] = useState<string[]>([]);
  const [isDisplaySearchSerialCodeModal, setIsDisplaySearchSerialCodeModal] = useState<boolean>(false);
  const [isDisplayFilterIdsResult, setIsDisplayFilterIdsResult] = useState<boolean>(false);
  const [selectableRowsVisibleOnly, setSelectableRowsVisibleOnly] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const accountStrings: AccountStrings = {
    action: '承認',
    userAccount: ACCOUNT.MANAGER,
    bossAccount: getBossAccount(ACCOUNT.MANAGER),
    subordinateAccount: getSubordinateAccount(ACCOUNT.MANAGER),
    imageFileName: 'manager',
  };

  const moveConfirmPage = (row: any) => window.open('/manager/report/' + row._id, '_blank');
  const moveEditPage = (row: any) => navigate('/manager/report/edit/' + row._id + '/lesson');
  const moveCopyLessonPage = (row: any) => navigate('/manager/report/copy/' + row._id + '/bigMediaCategory');
  const moveCopyLessonAndMediaPage = (row: any) => navigate('/manager/report/copy/' + row._id + '/work');

  const updateDisplayReports = async (phase: string, user: string) => {
    try {
      dispatch(startLoading());
      const originReports = await api.getReports(phase, user);
      setReports(originReports);
      setButtonDisable(0);
      setIsClearSelectedRow((prev) => !prev);
      setSelectedRows([]);
    } finally {
      dispatch(stopLoading());
    }
  };

  const setButtonDisable = (num: number) => {
    if (phaseFilter === PHASE.USER_CONFIRMED) {
      if (num === 0) {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(true);
      } else if (num === 1) {
        setIsDisableSubmitDeleteBtn(false);
        setIsDisableCopyBtn(false);
      } else {
        setIsDisableSubmitDeleteBtn(false);
        setIsDisableCopyBtn(false);
      }
    } else {
      if (num === 0) {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(true);
      } else if (num === 1) {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(false);
      } else {
        setIsDisableSubmitDeleteBtn(true);
        setIsDisableCopyBtn(false);
      }
    }
  };

  const handleSelectRow = ({ selectedRows }: { selectedRows: any }) => {
    setButtonDisable(selectedRows.length);
    setSelectedRows(selectedRows);
  };

  const submitItems = async (type: string) => {
    const phase = type === '承認' ? PHASE.MANAGER_CONFIRMED : PHASE.NOT_SUBMITTED;
    try {
      dispatch(startLoading());
      let targetItems: BulkUpdatePhase[] = [];
      if (type === '承認') {
        // 承認
        targetItems = selectedRows.map((item) => ({ _id: item._id, phase: phase }));
      } else {
        // 差戻
        const comment: Comment | null = sendBackReason ? { content: sendBackReason } : null;
        targetItems = selectedRows.map((item) => (comment ? { _id: item._id, phase: phase, comment: comment } : { _id: item._id, phase: phase }));
      }
      await api.bulkUpdate(targetItems);
      toast.success(`${type}しました。`, toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400 || e.response.status === 404) {
        toast.error(`${type}に失敗しました。画面を更新してください。`, toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter, userFilter);
  };

  const deleteItems = async () => {
    try {
      dispatch(startLoading());
      const deleteIds: string[] = selectedRows.map((item) => item._id);
      await api.bulkDelete(deleteIds);
      setIsDisplayConfirmDeleteModal(false);
      toast.success('削除が完了しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400 || e.response.status === 404) {
        toast.error('削除に失敗しました。画面を更新してください。', toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter, userFilter);
  };

  const stopFiltering = () => {
    dispatch(setIds([]));
    setIdsFilter([]);
    setIsDisplayFilterIdsResult(false);
    setNotFoundIds([]);
  };

  const setFilteredIds = (searchIds: string[]) => {
    dispatch(setIds(searchIds));
    setIdsFilter(searchIds);
    setNotFoundIds(searchIds.filter((id) => !reports.some((report) => report._id === id)));
    setIsDisplayFilterIdsResult(true);
  };

  const changeFormData = async (formData: any) => {
    try {
      dispatch(startLoading());
      const targetIds: string[] = selectedRows.map((item) => item._id);
      const defaultUser = await api.getDefaultUser();
      const data = await Promise.all(
        targetIds.map(async (id) => {
          const originReport = await api.getReport(id);
          return {
            phase: PHASE.USER_CONFIRMED,
            classInfo: formData,
            media: originReport.media,
            work: originReport.work,
            user: defaultUser,
          };
        })
      );
      if (data.length !== 0) {
        await api.bulkCreate(data);
        toast.success('コピー作成が完了しました。', toastConfig());
        setIsDisplayChangeClassInfoModal(false);
      }
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400) {
        toast.error('コピー作成に失敗しました。', toastConfig());
        return;
      }
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
    await updateDisplayReports(phaseFilter, userFilter);
  };

  const hiddenDownloadButtonRef = useRef<HTMLButtonElement>(null);
  const clickDownload = () => {
    if (hiddenDownloadButtonRef.current) {
      hiddenDownloadButtonRef.current.click();
    }
  };

  useEffect(() => {
    (async () => {
      await updateDisplayReports(phaseFilter, userFilter);
    })();
    // 利用報告詳細確認ページから戻ってきたら一覧を更新するイベントを追加
    const focusFunction = async () => await updateDisplayReports(phaseFilter, userFilter);
    window.addEventListener('focus', focusFunction, false);
    return () => window.removeEventListener('focus', focusFunction, false);
  }, [phaseFilter, userFilter]);

  useEffect(() => {
    (async () => {
      const profile: BaseUserInfo = await api.getProfile();
      const originReports = await api.getReports(phaseFilter, userFilter);
      setReports(originReports);
      setNotFoundIds(idsFilter.filter((id) => !originReports.some((report) => report._id === id)));
      setIsDisplayFilterIdsResult(true);
      const users: User[] = await api.getUsers();
      setUsers(users);
      removeAllTmpDataAndEditTypeById(profile._id);
    })();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
              利用報告一覧 ＜{globalInstitutionResponsibleTerm.year}年{globalInstitutionResponsibleTerm.month}月＞
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          {idsFilter.length !== 0 && isDisplayFilterIdsResult && (
            <SerialCodeSearchResult
              foundIdsNum={reports.filter((report) => idsFilter.includes(report._id)).length}
              notFoundIds={notFoundIds}
              stopFiltering={stopFiltering}
            />
          )}
          {idsFilter.length === 0 && (
            <>
              <Col md={{ span: 12 }} lg={{ span: 4 }}>
                <FilterPhase
                  onFilter={(event: any) => {
                    const phase = event.target.value;
                    dispatch(setPhase(phase));
                    setIsDisplayEditFlag(phase === PHASE.USER_CONFIRMED);
                    setPhaseFilter(phase);
                  }}
                  filterText={phaseFilter}
                />
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 1 }} className="ta-right pt-2 filter-arrow">
                <b>＞</b>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 5 }}>
                <FilterUser
                  onFilter={(event: any) => {
                    const user = event.target.value;
                    dispatch(setUser(user));
                    setUserFilter(user);
                  }}
                  filterText={userFilter}
                  options={users}
                  label="ユーザー"
                />
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 2 }} className="text-right">
                <Button
                  variant="secondary"
                  size="sm"
                  className="mt-2"
                  onClick={() => {
                    setIsDisplaySearchSerialCodeModal(true);
                  }}
                >
                  シリアルコード検索
                </Button>
              </Col>
            </>
          )}
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col md={{ span: 4 }}>
            <ReportsCheckboxModeComponent
              selectableRowsVisibleOnly={selectableRowsVisibleOnly}
              handleChangeFunction={() => {
                setIsClearSelectedRow((prev) => !prev);
                setSelectableRowsVisibleOnly((prev) => !prev);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReportsTable
              reports={reports.filter((report) => (idsFilter.length !== 0 ? idsFilter.includes(report._id) : true))}
              selectRow={handleSelectRow}
              moveConfirmPage={moveConfirmPage}
              moveEditPage={moveEditPage}
              moveCopyLessonPage={moveCopyLessonPage}
              moveCopyLessonAndMediaPage={moveCopyLessonAndMediaPage}
              isClearSelectedRow={isClearSelectedRow}
              isDisplayEdit={isDisplayEdit}
              isDisplayCopy={true}
              hiddenDLButtonRef={hiddenDownloadButtonRef}
              selectableRowsVisibleOnly={selectableRowsVisibleOnly}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={{ span: 12 }} lg={{ span: 6 }}>
            {idsFilter.length === 0 && (
              <div className="d-flex">
                <div className="mr-1">
                  <Button variant="primary" className="w-200" onClick={clickDownload}>
                    <Download size={20} />
                    Excelファイルで出力
                  </Button>
                </div>
                <div className="w-100 text-muted lh-1">
                  <small>「ステータス」及び「ユーザー」で絞り込んだ利用報告を、確認用のExcelファイルに出力します。</small>
                </div>
              </div>
            )}
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 6 }}>
            <div className="ta-right">
              <Button
                variant="danger"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmDeleteModal(true)}
              >
                削除
              </Button>
              <Button
                variant="primary"
                className="m-10"
                type="button"
                disabled={isDisableCopyBtn}
                onClick={() => setIsDisplayChangeClassInfoModal(true)}
              >
                授業情報を編集してコピー
              </Button>
              <Button
                variant="outline-primary"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmApproveModal(true)}
              >
                <FileEarmarkArrowUpFill size={20} />
                承認
              </Button>
              <Button
                variant="outline-secondary"
                className="m-10"
                type="button"
                disabled={isDisableSubmitDeleteBtn}
                onClick={() => setIsDisplayConfirmSendBackModal(true)}
              >
                <FileEarmarkArrowDownFill size={20} />
                差戻
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmBulkDeleteModal show={isDisplayConfirmDeleteModal} setShow={setIsDisplayConfirmDeleteModal} nextStep={deleteItems} />
      <ChangeLessonModal
        show={isDisplayChangeClassInfoModal}
        setShow={setIsDisplayChangeClassInfoModal}
        changeFormData={changeFormData}
        changeInstitution={() => {}}
        selectInstitutions={false}
        institutions={[]}
      />
      <ConfirmSendBackModal
        isDisplayModal={isDisplayConfirmSendBackModal}
        accountStrings={accountStrings}
        handleCloseModal={() => setIsDisplayConfirmSendBackModal(false)}
        handleClickOk={() => submitItems('差戻')}
        setReason={setSendBackReason}
      />
      <SearchSerialCodeModal show={isDisplaySearchSerialCodeModal} setShow={setIsDisplaySearchSerialCodeModal} filterIds={setFilteredIds} />
      <ConfirmApproveModal
        isDisplayModal={isDisplayConfirmApproveModal}
        accountStrings={accountStrings}
        handleCloseModal={() => setIsDisplayConfirmApproveModal(false)}
        handleClickOk={() => submitItems('承認')}
      />
    </>
  );
};

export default ShowReports;
