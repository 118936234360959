import NavigationAlertModal from '../modal/NavigationAlertModal';

export interface Props {
  isDisplayModal: boolean;
  handleCloseModal: () => void;
  handleClickOk: () => void;
}

const UnsavedReportAlertModal: React.FC<Props> = ({ isDisplayModal, handleCloseModal, handleClickOk }) => {
  const jsx = (
    <div style={{ fontSize: '18px', textAlign: 'center' }}>
      登録を完了せずに移動すると、
      <br />
      編集中のデータは破棄されます。
      <br />
      本当に<b style={{ color: 'red' }}>破棄しますか？</b>
    </div>
  );
  return <NavigationAlertModal message={jsx} isDisplayModal={isDisplayModal} handleCloseModal={handleCloseModal} handleClickOk={handleClickOk} />;
};

export default UnsavedReportAlertModal;
